import dayjs, { Dayjs } from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { Period, TimeIncrement } from 'types/graphql'
import { v4 as uuid } from 'uuid'

import { BILLING_SWAP_OVER_DATE, BRISBANE_TZ } from './constants'
import {
  AZURE_TOKEN_ERRORS,
  GoalStatusTypes,
  HUBS_URL,
  NewGoalStatusTypes,
  POLARITY,
  TIME_INCREMENT,
} from './enums'
import { isDevelopment, isLocal, isProduction, isStaging } from './environment'

const cache = {
  loadDayjsExtensions: false,
}

export const loadDayjsExtensions = () => {
  if (cache.loadDayjsExtensions) {
    return
  }
  dayjs.extend(relativeTime)
  dayjs.extend(timezone)
  dayjs.extend(isSameOrBefore)
  dayjs.extend(utc)
  dayjs.extend(isBetween)
  dayjs.extend(duration)
  dayjs.extend(quarterOfYear)

  cache.loadDayjsExtensions = true
}

export const getCallSource = (stack: string, stackDepth = 2) => {
  try {
    return stack?.split('\n')?.[stackDepth]?.replace('    at ', '') ?? 'unknown'
  } catch (e) {
    return 'error getting call source'
  }
}

export const getBrisbaneTime = (date?: Dayjs | Date | number | string) =>
  date ? dayjs.tz(date, BRISBANE_TZ) : dayjs().tz(BRISBANE_TZ)

export const getHubsBaseUrl = () => {
  if (isProduction) {
    return HUBS_URL.PRODUCTION
  } else if (isStaging) {
    return HUBS_URL.STAGING
  } else if (isDevelopment) {
    return HUBS_URL.DEVELOPMENT
  } else if (isLocal) {
    return HUBS_URL.LOCAL
  } else {
    return HUBS_URL.PRODUCTION
  }
}
export const getHubUrl = getHubsBaseUrl

export const nextTickPromise = () =>
  new Promise((resolve) => setTimeout(resolve, 0))

export const getUniqId = (): string => uuid().slice(0, 8).toUpperCase()

const emailCheckRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// eslint-disable-next-line no-control-regex
const invisibleCharacterFindRegex = /[^\x00-\x7F]/gm

export const isEmailValid = (email: string): boolean => {
  const isValid =
    emailCheckRegex.test(email) && !invisibleCharacterFindRegex.test(email)

  return isValid
}

export const hasInvisibleCharacters = (value: string) => {
  const result = invisibleCharacterFindRegex.test(value)
  return result
}

export const censorWord = function (text: string) {
  if (text.length < 3) {
    text = text.padEnd(3, '*')
  }
  return text[0] + '*'.repeat(text.length - 2) + text.slice(-1)
}

// This function should never throw an error
export const censorEmail = (email: string) => {
  try {
    const arr = email.split('@')
    const result = censorWord(arr[0]) + '@' + censorWord(arr[1])
    return {
      email: result,
      success: true,
    }
  } catch {
    return {
      email: '********@********',
      success: false,
    }
  }
}

export const lowerCaseFirstChar = (str: string): string => {
  if (str.length === 0) {
    return str
  } else if (str.length === 1) {
    return str.toLowerCase()
  }

  return str.charAt(0).toLowerCase() + str.slice(1)
}

export const toSentenceCase = (str: string) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase()
}

/**
 * @description Get the days difference between current time and X due date. Either X days until due or X days overdue
 * @param dueDate - The due date
 * @returns Positive or Negative number
 */

export const calculateDiffDays = (dueDate: string | Date): number => {
  // Init date
  const now = dayjs().format('YYYY-MM-DD')
  const due = dayjs(dueDate).format('YYYY-MM-DD')

  // Resetting the timestamp data - dayjs returns decimal points for diff (or rounds up/down) and is not always correct
  // initiating new dayjs maintains timestamps info and can skew the comparison
  const compareNow = dayjs(`${now}T00:00:00-00:00`)
  const compareDue = dayjs(`${due}T00:00:00-00:00`)

  // Return difference
  return compareDue.diff(compareNow, 'd', true)
}

/**
 * @description Returns goal status - also calculates if a goal is overdue
 * @param isComplete - Current DB value of goal status
 * @param dueDate - The due date
 * @param currentStatus - Current DB saved value
 * @returns Status either" Completed, Blocked, Overdue, In Progress
 */
export const calculateGoalStatus = (
  isComplete: boolean,
  dueDate: Date,
  currentStatus: string,
) => {
  // Goal Complete - return Complete
  if (isComplete) return 'Completed'

  // Goal Blocked - return Blocked
  if (currentStatus === 'Blocked') return currentStatus

  // Goal Overdue - return Overdue
  // i.e. Goal is 1 full day overdue
  if (
    calculateDiffDays(dueDate) <= -1 ||
    currentStatus === GoalStatusTypes.overdue
  )
    return GoalStatusTypes.overdue

  // Goal In Progress - return In Progress
  return 'In Progress'
}

export enum Rounding {
  Ceil,
  Floor,
  Round,
}

/**
 * @description Calculate the percentage progress of a value between X and Y
 * @param startValue - starting value for the % scale
 * @param currentValue - current position on the % scale
 * @param targetValue - ending value for the % scale
 * @returns Percent value
 */
export const calculatePercentageOf = (
  startValue: number,
  currentValue: number,
  targetValue: number,
  rounding = Rounding.Round,
): number => {
  const value = ((currentValue - startValue) / (targetValue - startValue)) * 100
  if (rounding === Rounding.Ceil) {
    return Math.ceil(value)
  } else if (rounding === Rounding.Floor) {
    return Math.floor(value)
  }

  return Math.round(value)
}

export const calculateNpsScore = (
  promoters: number,
  detractors: number,
  neutrals: number,
) => {
  const score = Math.round(
    ((promoters - detractors) / (promoters + detractors + neutrals)) * 100,
  )
  return isNaN(score) ? 0 : score
}

/**
 * Calculate time between two dates and return humanized string
 */
export const calculateTimeBetween = (
  startDate: Date | string,
  endDate: Date | string,
  humanize = true,
) => {
  // Init Dates
  const start = dayjs(startDate)
  const end = dayjs(endDate)

  // Calculate Diff
  let duration = 0

  if (start.isSameOrBefore(end, 'd')) {
    duration = Math.abs(start.diff(end, 'd'))
  } else {
    duration = -Math.abs(start.diff(end, 'd'))
  }

  // Humanize it
  const humanizedDuration = dayjs.duration(Math.abs(duration), 'd').humanize()

  return humanize ? humanizedDuration : duration.toString()
}

/**
 * @description Returns a new string with all new lines and excess white space removed
 */
export const removeExcessWhiteSpace = (str: string): string => {
  let result = str.replaceAll('\n', ' ')
  do {
    result = result.replaceAll('  ', ' ')
  } while (result.includes('  '))

  return result
}

export const isAzureTokenError = (error: unknown) => {
  const message = (error as Error)?.['message']

  if (!message) {
    return false
  }

  return Object.values(AZURE_TOKEN_ERRORS).includes(
    message as AZURE_TOKEN_ERRORS,
  )
}

/**
 * @description Returns a date given a time increment and a date
 */

export const getDateFromTimeIncrement = (
  timeIncrement: TimeIncrement,
  date: Date,
) => {
  const startDate = dayjs(date)
  switch (timeIncrement) {
    case TIME_INCREMENT.WEEK:
      return startDate.subtract(1, 'week').toDate()
    case TIME_INCREMENT.MONTH:
      return startDate.subtract(1, 'month').toDate()
    case TIME_INCREMENT.QUARTER:
      return startDate.subtract(3, 'month').toDate()
    case TIME_INCREMENT.YEAR:
      return startDate.subtract(1, 'year').toDate()
    case TIME_INCREMENT.DAYS7:
      return startDate.subtract(7, 'day').toDate()
    case TIME_INCREMENT.DAYS30:
      return startDate.subtract(30, 'day').toDate()
    case TIME_INCREMENT.DAYS90:
      return startDate.subtract(90, 'day').toDate()
    case TIME_INCREMENT.MONTHS12:
      return startDate.subtract(12, 'month').toDate()
  }
}

/**
 * @description Get the Polarity of a sentiment score to determine if it is positive, negative or neutral
 * @param score - Sentiment score (can be decimal)
 * @returns POLARITY - Positive, Negative, Neutral
 */

export const checkSentimentScorePolarity = (score?: number): POLARITY => {
  if (!score) {
    return POLARITY.NEUTRAL
  }

  if (score >= 6) {
    return POLARITY.POSITIVE
  } else if (score <= 4) {
    return POLARITY.NEGATIVE
  } else {
    return POLARITY.NEUTRAL
  }
}

export const getEndDateForPeriod = (
  anchorDate: Date | string,
  startDate: Date | string,
  period: Period,
): Dayjs => {
  if (period !== 'MONTHLY') {
    throw new Error(`Period '${period}' is not supported`)
  }
  const start = dayjs(startDate)

  const anchor = dayjs(anchorDate)

  const monthsSinceAnchor = start.diff(anchor, 'month')

  return anchor.add(monthsSinceAnchor + 1, 'month')
}

export const isDateAfterBillingSwapOver = (date: Dayjs) => {
  return date.isAfter(dayjs(BILLING_SWAP_OVER_DATE))
}

export const goalStatusChangeToNew = (status: GoalStatusTypes) => {
  switch (status) {
    case 'Completed':
      return 'Done'
    case 'In Progress':
      return 'On Track'
    case 'Blocked':
      return 'Off Track'
    case 'Overdue':
      return 'Overdue'
    default:
      return 'Not Started'
  }
}

export const goalStatusChangeToOld = (status: NewGoalStatusTypes) => {
  switch (status) {
    case 'Done':
      return 'Completed'
    case 'On Track':
      return 'In Progress'
    case 'Off Track':
      return 'Blocked'
    case 'Overdue':
      return 'Overdue'
    default:
      return 'Not Started'
  }
}

/**
 * @description Returns a race between a promise and a timeout, throwing an error if the promise times out.
 * Can be used to prevent long-running promises from blocking the event loop
 * @param promise - The promise
 * @param timeoutMs - The timeout in milliseconds
 * @param timeoutError - The error to throw if the promise times out
 */
export function promiseWithTimeout<T>(
  promise: Promise<T>,
  timeoutMs: number,
  timeoutError: Error,
): Promise<T> {
  const timeoutPromise = new Promise<T>((_, reject) =>
    setTimeout(() => reject(timeoutError), timeoutMs),
  )
  return Promise.race([promise, timeoutPromise])
}
